import React, { FC, useState } from "react";
import { Col, DatePicker, Form, Input, Modal, Row, Select, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { UserActions, userSelectors } from "../../store/users";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

interface EditUserDetailsFormProps {
  setSearchedTerm: React.Dispatch<React.SetStateAction<string>>;
  user_id: number;
  roleId?: number | null;
  trainingPeriod?: number | null;
  visible: boolean;
  userName: string;
  email: string;
  phoneNo: string;
  address: string;
  emp_date?: string;
  status : number;
  onClose: () => void;
}
interface EditFormValues {
  name: string;
  email: string;
  phoneNo: string;
  address: string;
  empDate: string;
  status : number;
  role_id ?: number | null;
  training_period ?: number | null;
}

const EditUserDetailsForm: FC<EditUserDetailsFormProps> = ({
  setSearchedTerm,
  user_id,
  roleId,
  trainingPeriod,
  visible,
  userName,
  email,
  phoneNo,
  address,
  emp_date,
  status,
  onClose,
}) => {
  const [editUserForm] = Form.useForm<EditFormValues>();
  const SelecteduserId = useAppSelector(userSelectors.getUserModelId);
  const [userStatus, setUserStatus] = useState<number>(status)
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(userSelectors.getUserRoleSelector);

  const onFinish = (values: EditFormValues) => {
    dispatch(
      UserActions.updateUserDetails({
        id: user_id,
        name: values.name ?? userName,
        email: values.email ?? email,
        phone_number: values.phoneNo ?? phoneNo,
        address: values.address ?? address,
        role_id: values.role_id ?? roleId,
        training_period: values.training_period ?? trainingPeriod,
        joining_date: moment(values.empDate).format("YYYY-MM-DD") ?? emp_date,
        status : userStatus 
      })
    );
    dispatch(UserActions.getUserDetails({}));
    setSearchedTerm('');
    onClose();
  };
  return (
    <>
      {SelecteduserId === user_id && (
        <Modal
          title="Edit User Details"
          visible={visible}
          onOk={editUserForm.submit}
          okText="Submit"
          onCancel={onClose}
        >
          <Form
            form={editUserForm}
            initialValues={{
              name: userName,
              email: email,
              phoneNo: phoneNo,
              address: address,
              role_id: roleId,
              training_period: trainingPeriod,
              empDate: moment(emp_date, "YYYY-MM-DD")
            }}
            layout={"vertical"}
            onFinish={onFinish}
          >
            <Row gutter={[12, 12]}>
              <Col span={8}>Name</Col>
              <Col span={16}>
                <Form.Item name="name">
                  <Input
                    type="text"
                    size="large"
                    placeholder="Name"
                    className="input"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>Email Id</Col>
              <Col span={16}>
                <Form.Item name="email">
                  <Input
                    type="email"
                    size="large"
                    placeholder="Email"
                    className="input"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>Phone Number</Col>
              <Col span={16}>
                <Form.Item name="phoneNo">
                  <Input
                    type="number"
                    size="large"
                    placeholder="Phonee number"
                    className="input"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>Address</Col>
              <Col span={16}>
                <Form.Item name="address">
                  <TextArea
                    size="large"
                    placeholder="Address"
                    className="input"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>Employee Role</Col>
              <Col span={16}>
                <Form.Item name={"role_id"}>
                  <Select>
                    {userRole.map((role) => (
                      <Select.Option key={role.id} value={role.id}>
                        {role.type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>Employment Date</Col>
              <Col span={16}>
                <Form.Item name="empDate">
                  <DatePicker
                    inputReadOnly={true}
                    placeholder="Select Employement Date"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>Training Period</Col>
              <Col span={16}>
              <Form.Item name="training_period">
                  <Select> 
                    <Select.Option value={1}> 1 Month</Select.Option>
                    <Select.Option value={2}> 2 Months</Select.Option>
                    <Select.Option value={3}> 3 Months</Select.Option>
                    <Select.Option value={4}> 4 Months</Select.Option>
                    <Select.Option value={5}> 5 Months</Select.Option>
                    <Select.Option value={6}> 6 Months</Select.Option>
                  </Select>
                </Form.Item>
              </Col>              
              <Col span={8}>Status</Col>
              <Col span={16}>
                <Form.Item name="status">
                  <Switch
                      defaultChecked={userStatus === 1 ?true :false}                        
                      onChange={(val) => {
                        setUserStatus(val === true ? 1 : 0)
                      }}
                  ></Switch>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditUserDetailsForm;
