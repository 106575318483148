import { FC, useState, useEffect, useCallback } from "react";
import {
  Modal,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  Form,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { leaveActions, leaveSelectors } from "../../../store/leaves";
import moment from "moment";
import { authSelectors } from "../../../store/auth";
const { Option } = Select;
interface LeaveRequestInterface {
  startDate: string;
  endDate: string;
  reason: string;
  status: string;
  type: string;
  day: string;
  adjustmentDate?: string;
  halfLeaveTime: string;
  isEmergency: string;
  isHalfLeave: string;
  isAlternateShift: string;
  shifttime: string;
}

const labelsCols = { xs: 12, md: 12, lg: 12 };
const fieldCols = { xs: 12, md: 12, lg: 12 };

const CreateLeaveRequestForm: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(leaveActions.getLeaveTypes({})); // calling get leave types method
  }, [dispatch]);
  const leaveTypes = useAppSelector(leaveSelectors.getLeaveTypes); // fetch list of leave types
  const leaveLoader = useAppSelector(leaveSelectors.getLeaveLoader); // fetch list of leave types
  const user = useAppSelector(authSelectors.getUser); // fetch user details

  const isEmployeeWithTraining = useCallback(() => {
    const casualLeaveId = leaveTypes.find((l) => l.type === "Casual Leave")?.id;
    return casualLeaveId === 1 && user?.is_training // Id will be 1 because he has been assigned leaves of employees
  },[leaveTypes]);

  const onFinish = (values: LeaveRequestInterface) => {
    dispatch(
      leaveActions.makeLeaveRequest({
        start_date: moment(values.startDate).format("YYYY-MM-DD"),
        end_date:
          (Number(values.type) === 4 || Number(values.type) === 10)
            ? moment(values.startDate).format("YYYY-MM-DD")
            : moment(values.endDate).format("YYYY-MM-DD"),
        is_emergency: values.isEmergency ?? false,
        is_half_leave: values.isHalfLeave ?? false,
        is_alternate_shift: values.isAlternateShift ?? false,
        half_leave_time: values.shifttime ?? values.halfLeaveTime,
        adjustment_date: moment(values.adjustmentDate).format("YYYY-MM-DD"),
        reason: values.reason,
        type: values.type,
      })
    );
    dispatch(leaveActions.leaveModelVisible({ visible: false }))
    leaveForm.resetFields();
    dispatch(leaveActions.getYourLeaves({}));

  };
  const [halfday, setHalfday] = useState(false);
  const [alternateShift, setAlternateShift] = useState(false);
  const [emergency, setEmergency] = useState(false);
  const [leaveType, setLeaveType] = useState("");
  const [leaveForm] = Form.useForm();
  const updated_start_date = Form.useWatch('startDate', leaveForm);
  const updated_end_date = Form.useWatch('endDate', leaveForm);
  const Modalvisible = useAppSelector(leaveSelectors.leaveModalVisible);
  return (
    <Modal
      title="Make Leave Request"
      visible={Modalvisible}
      onOk={leaveForm.submit}
      confirmLoading={leaveLoader}
      okText="Submit"
      onCancel={() => {
        leaveForm.resetFields();
        dispatch(leaveActions.leaveModelVisible({ visible: false }))
      }}
    >
      <Form form={leaveForm} layout={"vertical"} onFinish={onFinish}>
        <Row>
          <Col {...labelsCols}>Type </Col>
          <Col {...fieldCols}>
            <Form.Item
              name="type"
              style={{ width: "inherit" }}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={"Select Leave Type"}
                onChange={(type) => {
                  setHalfday(false)
                  setAlternateShift(false)
                  setEmergency(false)
                  setLeaveType(type.toString());
                  leaveForm.setFieldsValue({ type: type });
                }}
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                {(isEmployeeWithTraining() ? leaveTypes.filter(l => !([1,2,3].includes(l.id))) : leaveTypes)?.map((leave) =>
                  user?.is_training ? (
                    ([4, 5, 6, 7, 8, 9, 10].includes(leave.id)) && (
                      <Option key={leave.id} value={leave.id}>
                        {leave.type}
                      </Option>
                    )
                  ) : (
                    <Option key={leave.id} value={leave.id}>
                      {leave.type}
                    </Option>                    
                  )
                )}
              </Select>
            </Form.Item>
          </Col>

          {[1, 3, 4, 7, 8, 10].includes(Number(leaveType)) && (
            <>
              <Col {...labelsCols}>Emergency day </Col>
              <Col {...fieldCols}>
                <Form.Item name="isEmergency">
                  <Checkbox
                    checked={emergency}
                    onChange={(val) => {
                      setEmergency(val.target.checked);
                      leaveForm.setFieldsValue({
                        isEmergency: val.target.checked,
                      });
                    }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </>
          )}

          {/* Don't show half day option for alternate shift as it's covered in other type of leaves & also hide that option for multiple day leaves */}
          {([1, 2, 3, 4, 5, 7, 8, 9, 10].includes(Number(leaveType)) && ((updated_start_date && updated_end_date) ? (updated_end_date?.diff(updated_start_date, 'days') === 0) : true)) && (
              <>
                <Col {...labelsCols}>Half day </Col>
                <Col {...fieldCols}>
                  <Form.Item name="isHalfLeave">
                    <Checkbox
                      checked={halfday}
                      onChange={(value) => {
                        setHalfday(value.target.checked);
                        leaveForm.setFieldsValue({
                          isHalfLeave: value.target.checked,
                        });
                      }}
                    ></Checkbox>
                  </Form.Item>
                </Col>
              </>
            )}

          {halfday === true && (
            <>
              <Col {...labelsCols}>Alternate shift </Col>
              <Col {...fieldCols}>
                <Form.Item name="isAlternateShift">
                  <Checkbox
                    checked={alternateShift}
                    onChange={(value) => {
                      setAlternateShift(value.target.checked);
                      leaveForm.setFieldsValue({
                        isAlternateShift: value.target.checked,
                      });
                    }}
                  ></Checkbox>
                </Form.Item>
              </Col>
            </>
          )}
          {[4, 10].includes(Number(leaveType)) ? (
            //When taking adjustment leave, start_date should be replaced by its name leave date.
            <Col {...labelsCols}>Leave Date</Col>
          ) : (
            <Col {...labelsCols}>Start Date</Col>
          )}
          <Col {...fieldCols}>
            <Form.Item name="startDate" rules={[{ required: true }]}>
              <DatePicker
                inputReadOnly={true}
                placeholder="Select start date"
                format="YYYY-MM-DD"
                disabledDate={(current) =>
                  // Disable dates that are Saturdays or Sundays
                  (current && ((current.day() === 6 && leaveType !== "6") || current.day() === 0)) ||
                  // Add your other conditions here
                  (!current ||
                  (user?.is_training === false &&
                    emergency === false &&
                    leaveType !== "2" &&
                    leaveType !== "6")
                    ? current.isSameOrBefore(
                        moment().format("LT") <= "12:30 PM"
                          ? moment().add(0, "days")
                          : moment().add(1, "days")
                      )
                    : (user?.is_training === true &&
                        emergency === false &&
                        leaveType !== "5" &&
                        leaveType !== "6" &&
                        current.isSameOrBefore(
                          moment().format("LT") <= "12:30 PM"
                            ? moment().add(0, "days")
                            : moment().add(1, "days")
                        )) ||
                      current.isBefore(moment().add(-1, "days")))
                }
              />
            </Form.Item>
          </Col>
          {!([4, 10].includes(Number(leaveType))) && ( //End_date should be hidden if more than 1 leave is taken in adjustment leave
              <>
                <Col {...labelsCols}>End Date </Col>
                <Col {...fieldCols}>
                  <Form.Item name="endDate" rules={[{ required: true }]}>
                    <DatePicker
                      inputReadOnly={true}
                      placeholder="Select end date"
                      format="YYYY-MM-DD"
                      disabledDate={(current) =>
                        // Disable dates that are Saturdays or Sundays
                        (current &&
                          ((current.day() === 6 && leaveType !== "6") || current.day() === 0)) ||
                        // Add your other conditions here
                        (!current ||
                        (user?.is_training === false &&
                          emergency === false &&
                          leaveType !== "2" &&
                          leaveType !== "6")
                          ? current.isSameOrBefore(
                              moment().format("LT") <= "12:30 PM"
                                ? moment().add(0, "days")
                                : moment().add(1, "days")
                            )
                          : (user?.is_training === true &&
                              emergency === false &&
                              leaveType !== "5" &&
                              leaveType !== "6" &&
                              current.isSameOrBefore(
                                moment().format("LT") <= "12:30 PM"
                                  ? moment().add(0, "days")
                                  : moment().add(1, "days")
                              )) ||
                            current.isBefore(moment().add(-1, "days")))
                      }
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          {(halfday === true && ((updated_start_date && updated_end_date) ? (updated_end_date?.diff(updated_start_date, 'days') === 0) : true)) && (
            <>
              <Col {...labelsCols}>Leave Time </Col>
              <Col {...fieldCols}>
                <Form.Item name="halfLeaveTime" rules={[{ required: true }]}>
                  <Select
                    placeholder={"Select Time"}
                    onChange={(val) => {
                      leaveForm.setFieldsValue({ halfLeaveTime: val });
                    }}
                  >
                    {alternateShift === true ? (
                      <>
                        <Option value="08:30 AM to 12:30 PM">
                          08:30 AM to 12:30 PM
                        </Option>
                        <Option value="12:30 PM to 04:30 PM">
                          12:30 PM to 04:30 PM
                        </Option>
                        <Option value="04:30 PM to 08:30 PM">
                          04:30 PM to 08:30 PM
                        </Option>
                      </>
                    ) : (
                      <>
                        <Option value="10:30 AM to 02:30 PM">
                          10:30 AM to 02:30 PM
                        </Option>
                        <Option value="02:30 PM to 06:30 PM">
                          02:30 PM to 06:30 PM
                        </Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          {[4, 10].includes(Number(leaveType))  && ( // For showing this option for interns & employees 
            <>
              <Col {...labelsCols}> Adjustment Date </Col>
              <Col {...fieldCols}>
                <Form.Item name="adjustmentDate" rules={[{ required: true }]}>
                  <DatePicker
                    placeholder="Select Adjustment date"
                    format="YYYY-MM-DD"
                    disabledDate={(d: any) =>
                      !d ||
                      // Check if the date is Sunday (day 0)
                      d.isBefore(moment().add(-1, "days")) ||
                      // Disable all days that are not Saturdays
                      d.day() !== 6 ||
                      // Disable dates more than 30 days in the future from today
                      d.isAfter(moment(updated_start_date).add(user?.gender === "M" ? 30 : 40, "days"))
                    }
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col {...labelsCols}>Reason </Col>
          <Col {...fieldCols}>
            <Form.Item name="reason" rules={[{ required: true }]}>
              <TextArea
                placeholder="Please write valid reason "
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
          {leaveType === "6" && (
            <>
              <Col {...labelsCols}>Shift Time </Col>
              <Col {...fieldCols}>
                <Form.Item name="shifttime" rules={[{ required: true }]}>
                  <Select
                    placeholder={"Select Shift Time"}
                    onChange={(val) => {
                      leaveForm.setFieldsValue({ shifttime: val });
                    }}
                  >
                    <Option value="08:30 AM to 04:30 PM">08:30 AM to 04:30 PM</Option>
                    <Option value="12:30 PM to 08:30 PM">12:30 PM to 08:30 PM</Option>
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateLeaveRequestForm;
